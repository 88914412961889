.header {
    flex: 0 1 auto;
    padding: 0.5em 1em !important;
}

#brand {
    margin-left: 0.5em;
}

a {
    text-decoration: none !important;
}

