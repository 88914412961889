.carousel-card {
    padding: 0em 1em 0.5em;
}
.carousel {
    padding: 1em;
    width: 400px;
}

@media only screen and (max-width: 768px) {
    .carousel {
        padding: 1em;
        width: 300px;
    }
}