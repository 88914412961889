.results {
    overflow-y: scroll;
    overflow-x: hidden;
    display: inline-block;
    height: 480px;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-size: 0.9rem;
}

@media only screen and (max-width: 768px) {
    .results {
        display: flex;
        flex-direction: column !important;
        height: 200px;
        margin: 0em;
        overflow: scroll;
    }
}