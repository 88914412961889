.map {
    width: 100%;
    display: flex;
    flex: 1 1 auto;
    border-radius: 1em;
}

@media only screen and (max-width: 768px) {
    .map {
        width: 100%;
        height: 500px;
        display: flex;
        flex: 1 1 auto;
        border-radius: 1em;
    }
}