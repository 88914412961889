.result {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    gap: 1em;
}

.dialog-title {
    display: flex;
    align-items: center;
    padding: 0.4em 0.4em 0.4em 0.7em !important;
}

.dialog-content {
    display: flex;
    flex-direction: column;
}

.result-text {
    font-weight: 500;
}

.result-name {
    padding: auto !important;
    height: 100% !important;
}