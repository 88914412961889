.content {
    display: flex;
    flex: 1 1 auto;
    gap: 1em;
    margin: 1em;
}


@media only screen and (max-width: 768px) {
    .content {
        display: flex;
        flex-direction: column;
        gap: 1em;
        margin: 1em;
        padding-bottom: 0.5em;
    }
}