.sidebar {
    width: 400px;
    display: flex;
    flex-direction: column;
    height: 100%;
    bottom: 0;
}

.form-input {
    display: flex;
    gap: 0.5em;
}

.calculate {
    margin-top: auto !important;
    font-size: large !important;
}

button {
    text-transform: none !important;
}

#spinner {
    margin: auto;
}

@media only screen and (max-width: 768px) {
    .sidebar {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        border-radius: 1em;
        gap: 1em;
    }
    .calculate {
        margin-top: 0px !important;
        font-size: large !important;
    }
}